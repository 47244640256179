<template>
    <div class="mt-3 ml-0">
        <v-sheet class="dense-inputs">
            <v-row no-gutters dense>
                <v-col cols="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left filter-width-250"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <v-btn class="ml-3" @click="openMarkAsPaidDialog" v-if="paidItems.length > 0 && $can('update','Commission')"><v-icon color="black darken-1" small class="mr-1">far fa-money-bill-alt</v-icon>{{ $t('message.markAsPaid') }}</v-btn>
                    <v-spacer/>
                    <ExportTableSalesCommission
                        :export-conditions="exportConditions"
                        :export-data="paidItems"
                        :export-fields="headers"
                        :export-has-agent-commissions="hasAgentCommissions"
                        :export-name="'paid'"
                        class="ml-3"
                    ></ExportTableSalesCommission>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loadingItems"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,75,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="paidItems"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="pt-0 pl-0 appic-table-light specification-table"
            dense
            id="intakeTable"
            item-key="index"
            ref="commissionPaidList"
            sort-by="index"
        >
            <template v-slot:body.prepend>
                <tr>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm" style="text-align: right !important;">{{ $t('message.totals') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-1 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.cfrCifValue,'0,0.00') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">USD</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-1 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.margin,'0,0.00') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-1 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.marginPct,'0.0') + '%' }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-1 pl-1 font-sm" style="text-align: right !important;" v-if="hasAgentCommissions">{{ formatThisNumber(totals.agentCommission,'0,0.00') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-1 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.newMargin,'0,0.00') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-1 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.newMarginPct,'0.0') + '%' }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left" v-if="$can('update','Commission')"></th>
<!--                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left" v-if="$can('update','Commission')"></th>-->
                </tr>
            </template>
            <template v-slot:item.index="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon color="black darken-1">more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <v-list-item class="font-sm" @click="openLink(item.Paid.link)" v-if="item.Paid.link != null"><v-icon color="black darken-1" small class="mr-1">fas fa-binoculars</v-icon>{{ $t('message.viewSalesDocument') }}</v-list-item>
                        <v-list-item class="font-sm" @click="openLink(item.Paid.poLink)" v-if="item.Paid.poLink != null"><v-icon color="black darken-1" small class="mr-1">fas fa-binoculars</v-icon>{{ $t('message.viewPurchaseOrder') }}</v-list-item>
                        <v-list-item class="font-sm" @click="openLink(item.Paid.accountLink)" v-if="item.Paid.accountLink != null"><v-icon color="black darken-1" small class="mr-1">fas fa-binoculars</v-icon>{{ $t('message.viewGrossMargin') }}</v-list-item>
<!--                        <v-list-item class="font-sm" @click="openMarkAsPaidDialog(item.Paid.invoiceId, item.Paid.invoiceNo, item.Paid.invoiceType)"><v-icon color="black darken-1" small class="mr-1">far fa-money-bill-alt</v-icon>{{ $t('message.markAsPaid') }}</v-list-item>-->
                    </v-list>
                </v-menu>
            </template>
            <template v-slot:item.Paid.intakeDate="{ item }">
                {{ item.Paid.intakeDate != '0000-00-00' ? formatDate(item.Paid.intakeDate) : '' }}
            </template>
            <template v-slot:item.Paid.documentNo="{ item }">
                <span class="font-weight-bold">{{ item.Paid.documentNo }}</span>
            </template>
            <template v-slot:item.Paid.invoiceNo="{ item }">
                <span class="font-weight-bold">{{ item.Paid.invoiceNo }}</span>
            </template>
            <template v-slot:item.Paid.value="{item}">
                <div class="text-no-wrap text-right" v-if="item.Paid.value != null">
                    {{ formatThisNumber(item.Paid.value, '0,0.00' ) }}
                </div>
            </template>
            <template v-slot:item.Paid.usdExRate="{item}">
                <div class="text-no-wrap text-right" v-if="item.Paid.usdExRate != null">
                    {{ formatThisNumber(item.Paid.usdExRate, '0,0.000000' ) }}
                </div>
            </template>
            <template v-slot:item.Paid.convertedValue="{item}">
                <div class="text-no-wrap text-right" v-if="item.Paid.convertedValue != null">
                    {{ formatThisNumber(item.Paid.convertedValue, '0,0.00' ) }}
                </div>
            </template>
            <template v-slot:item.Paid.margin="{item}">
                <div class="text-no-wrap text-right" v-if="item.Paid.margin != null">
                    {{ formatThisNumber(item.Paid.margin, '0,0.00' ) }}
                </div>
            </template>
            <template v-slot:item.Paid.marginPct="{item}">
                <div class="text-no-wrap text-right" v-if="item.Paid.marginPct != null">
                    {{ formatThisNumber(item.Paid.marginPct, '0,0.0' ) }}
                </div>
            </template>
            <template v-slot:item.Paid.agent_commission="{item}">
                <div class="text-no-wrap text-right red--text text-darken-1" v-if="item.Paid.agent_commission != null && item.Paid.agent_commission > 0">
                    {{ formatThisNumber(item.Paid.agent_commission, '0,0.00' ) }}
                </div>
                <div class="text-right" v-else>0.00</div>
            </template>
            <template v-slot:item.Paid.newMargin="{item}">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }" v-if="item.Paid.invoiceType == 'debit_note'">
                        <div class="text-no-wrap green--text text--darken-4 font-weight-bold text-right" v-on="on" style="cursor: pointer" v-if="item.Paid.newMargin != null" @click="printGrossMargin(item.Paid.accountId, 'dn')">
                            {{ formatThisNumber(item.Paid.newMargin, '0,0.00' ) }}
                        </div>
                    </template>
                    <template v-slot:activator="{ on, attrs }" v-else>
                        <div class="text-no-wrap green--text text--darken-4 font-weight-bold text-right" v-on="on" style="cursor: pointer" v-if="item.Paid.newMargin != null" @click="printGrossMargin(item.Paid.accountId, 'gm')">
                            {{ formatThisNumber(item.Paid.newMargin, '0,0.00' ) }}
                        </div>
                    </template>
                    <span>{{ $t('message.viewGrossMargin') }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.Paid.newMarginPct="{item}">
                <div class="text-no-wrap text-right" v-if="item.Paid.newMarginPct != null">
                    {{ formatThisNumber(item.Paid.newMarginPct, '0,0.0' ) }}
                </div>
            </template>
            <template v-slot:item.Paid.commissionPaidAmount="{item}">
                <div class="text-no-wrap text-right" v-if="item.Paid.commissionPaidAmount != null">
                    {{ formatThisNumber(item.Paid.commissionPaidAmount, '0,0.00' ) }}
                </div>
            </template>
            <template v-slot:item.Paid.commissionPaidDate="{ item }">
                {{ item.Paid.commissionPaidDate != '0000-00-00' ? (item.Paid.commissionPaidDate != null ? formatDate(item.Paid.commissionPaidDate) : $t('message.unpaid')) : $t('message.na') }}
            </template>
        </v-data-table>
        <PaySelectedSalesCommission
            :invoices="selectedPaidItems"
            :dialog.sync="paySalesCommissionDialog"
            @dialog-closed="paySalesCommissionDialogClosed"
            @commissions-paid="salesCommissionsPaid"
        ></PaySelectedSalesCommission>
    </div>
</template>

<script>
import {formatDate, numberFormat} from "Helpers/helpers";
import {v4 as uuidv4} from "uuid";
import {mapFields} from "vuex-map-fields";
import {api} from "Api";

const PaySelectedSalesCommission = () => import("Components/Appic/PaySelectedSalesCommission");
const ExportTableSalesCommission = () => import("Components/Appic/ExportTableSalesCommission");

export default {
    name: "CommissionPaidList",
    components: {ExportTableSalesCommission, PaySelectedSalesCommission},
    props: ['loadingItems','paidItems','commission-paid'],
    data() {
        return {
            exportParams: [],
            loading: {
                filterResults: false
            },
            paySalesCommissionDialog: false,
            searchTerm: null,
            // selectedInvoiceId: null,
            // selectedInvoiceNo: null,
            // selectedInvoiceType: null,
            tableOptions: {
                page: 1
            },
            totals: {
                agentCommission: 0,
                commission: 0,
                cfrCifValue: 0,
                margin: 0,
                marginPct: 0,
                newMargin: 0,
                newMarginPct: 0
            }
        }
    },
    computed: {
        ...mapFields('commission',{
            hasAgentCommissions: 'hasAgentCommissions',
            exportConditions: 'exportConditions'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        exportFileName() {
            return 'intake-list.xls'
        },
        exportFileFields() {
            let fields = {}
            this.headers.forEach(header => {
                if(header.export) {
                    fields[header.text] = header.value
                }
            })
            return fields
        },
        formatDate: () => formatDate,
        headers() {
            let headers = [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'index',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: false,
                },
                {
                    id: 2,
                    text: this.$t('message.monthYear'),
                    value: 'Paid.paidMonthString',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 3,
                    text: this.$t('message.country'),
                    value: 'Paid.country',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 4,
                    text: this.$t('message.salesColleague'),
                    value: 'Paid.salesContact',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 5,
                    text: this.$t('message.debitNoteOrInvoice'),
                    value: 'Paid.invoiceNo',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 6,
                    text: this.$t('message.contractOrSalesOrder'),
                    value: 'Paid.documentNo',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 7,
                    text: this.$t('message.intakeDate'),
                    value: 'Paid.intakeDate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 8,
                    text: this.$t('message.customer'),
                    value: 'Paid.customer',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 9,
                    text: this.$t('message.cfrCif'),
                    value: 'Paid.value',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 10,
                    text: this.$t('message.cur'),
                    value: 'Paid.currency',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 11,
                    text: this.$t('message.exRate'),
                    value: 'Paid.usdExRate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 12,
                    text: this.$t('message.cfrCif'),
                    value: 'Paid.convertedValue',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 13,
                    text: this.$t('message.cur'),
                    value: 'Paid.marginCurrency',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 14,
                    text: this.$t('message.margin'),
                    value: 'Paid.margin',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
                {
                    id: 15,
                    text: this.$t('message.marginPct'),
                    value: 'Paid.marginPct',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                },
            ];
            let i = 16
            if(this.hasAgentCommissions === true) {
                headers.push(
                    {
                        id: i,
                        text: this.$t('message.agtCom'),
                        value: 'Paid.agent_commission',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-end',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                );
                i++;
            }

            headers.push({
                    id: i,
                    text: this.$t('message.newMargin'),
                    value: 'Paid.newMargin',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                });
            i++;
            headers.push({
                    id: i,
                    text: 'NM %',
                    value: 'Paid.newMarginPct',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: true,
                    sortable: false,
                    export: true
                });

            if(this.$can('update','Commission')){
                i++;
                headers.push(
                    {
                        id: i,
                        text: this.$t('message.commissionDatePaid'),
                        value: 'Paid.commissionPaidDate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: false
                    }
                )
            }
            return headers
        },
        selectedPaidItems() {
            if(this.paidItems.length > 0){
                let selected = []
                this.paidItems.forEach(pi => {
                    selected.push({
                        invoiceId: pi.Paid.invoiceId,
                        invoiceType: pi.Paid.invoiceType,
                        margin: pi.Paid.newMargin,
                        paidMonth: pi.Paid.paidMonth,
                        salesContactId: pi.Paid.salesContactId
                    })
                })
                return selected
            }
            return []
        }
    },
    methods: {
        computeTotals() {
            let _this = this
            this.$nextTick().then(function(){

                _this.totals.cfrCifValue = 0
                _this.totals.margin = 0
                _this.totals.marginPct = 0
                _this.totals.newMargin = 0
                _this.totals.newMarginPct = 0
                _this.totals.commission = 0
                _this.totals.agentCommission = 0

                let items = _this.$refs.commissionPaidList.$children[0].filteredItems
                items.forEach((item) => {
                    _this.totals.cfrCifValue += item.Paid.convertedValue
                    _this.totals.margin += item.Paid.margin
                    _this.totals.newMargin += item.Paid.newMargin
                    _this.totals.commission += item.Paid.commissionPaidAmount
                    _this.totals.agentCommission += item.Paid.agent_commission
                })
                if(_this.totals.cfrCifValue > 0){
                    _this.totals.marginPct = (_this.totals.margin / _this.totals.cfrCifValue)*100
                    _this.totals.newMarginPct = (_this.totals.newMargin / _this.totals.cfrCifValue)*100
                }
            })
        },
        // exportTableToExcel() {
        //     this.$toast.success(this.$t('message.successes.exportExcelPleaseWaitForDownload'),
        //         {
        //             timeout: 3000,
        //             color: 'info',
        //             classes: ['icon-float-left'],
        //             icon: 'check_circle_outline',
        //             x: 'centered',
        //             y: 'top'
        //         }
        //     )
        // },
        // exportTableToPdf() {
        //     this.$toast.success(this.$t('message.successes.exportPdfPleaseWaitForDownload'),
        //         {
        //             timeout: 3000,
        //             color: 'info',
        //             classes: ['icon-float-left'],
        //             icon: 'check_circle_outline',
        //             x: 'centered',
        //             y: 'top'
        //         }
        //     )
        // },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        paySalesCommissionDialogClosed () {
            this.paySalesCommissionDialog = false;
            // this.selectedInvoiceId = null
            // this.selectedInvoiceNo = null
            // this.selectedInvoiceType = nul
        },
        openLink(link){
            let tab = window.open(link,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        openMarkAsPaidDialog(invoiceId, invoiceNo, invoiceType) {
            this.paySalesCommissionDialog = true
            // this.selectedInvoiceId = invoiceId
            // this.selectedInvoiceNo = invoiceNo
            // this.selectedInvoiceType = invoiceType
        },
        getPrintPdfUrl(grossMarginId, type) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print-url/" + type + "/" + grossMarginId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printGrossMargin(grossMarginId, type) {
            this.getPrintPdfUrl(grossMarginId, type)
                .then(response => {
                    if(response.data.status === 'success') {
                        let tab = window.open(response.data.url, '_blank');
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                })
        },
        salesCommissionsPaid() {
            this.$emit('commission-paid')
        },
        // viewAccountPdf(accountId, accountDocumentTitle, invoiceType){
        //     if(accountId) {
        //         if(invoiceType == 'debit_note'){
        //             let document = encodeURIComponent('DN ' + accountDocumentTitle)
        //             let tab = window.open(
        //                 process.env.VUE_APP_PDF_SERVER
        //                 + '/api/render/'
        //                 + document
        //                 + '?url='
        //                 + process.env.VUE_APP_BASE_URL
        //                 + '/v1/debitnotes/print/'
        //                 + accountId
        //                 + '/'
        //                 + uuidv4()
        //                 + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
        //                 , "_blank");
        //             tab.name = 'tab_' + Math.floor(Math.random() * 10000)
        //             this.openedTabs.push(tab.name)
        //         } else {
        //             let document = encodeURIComponent('GM ' + accountDocumentTitle)
        //             let source = 'invoice'
        //             if(invoiceType == 'wh_invoice') source = 'whsales_invoice'
        //             let tab = window.open(
        //                 process.env.VUE_APP_PDF_SERVER
        //                 + '/api/render/'
        //                 + document
        //                 + '?url='
        //                 + process.env.VUE_APP_BASE_URL
        //                 + '/v1/gross-margins/print/'
        //                 + source
        //                 + '/'
        //                 + accountId
        //                 + '/'
        //                 + uuidv4()
        //                 + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
        //                 , "_blank");
        //             tab.name = 'tab_' + Math.floor(Math.random() * 10000)
        //             this.openedTabs.push(tab.name)
        //         }
        //     } else {
        //
        //     }
        // },
        // viewAccountPdfBlob (accountId, accountDocumentTitle, invoiceType){
        //     if(accountId) {
        //         if(invoiceType == 'debit_note'){
        //             // let document = encodeURIComponent('DN ' + accountDocumentTitle)
        //             let tab = window.open(
        //                 process.env.VUE_APP_BASE_URL
        //                 + '/v1/debitnotes/print-v2/'
        //                 + accountId
        //                 , "_blank");
        //             tab.name = 'tab_' + Math.floor(Math.random() * 10000)
        //             this.openedTabs.push(tab.name)
        //         } else {
        //             // let document = encodeURIComponent('GM ' + accountDocumentTitle)
        //             let source = 'invoice'
        //             if(invoiceType == 'wh_invoice') source = 'whsales_invoice'
        //             let tab = window.open(
        //                 process.env.VUE_APP_BASE_URL
        //                 + '/v1/gross-margins/print-v2/'
        //                 + source
        //                 + '/'
        //                 + accountId
        //                 , "_blank");
        //             tab.name = 'tab_' + Math.floor(Math.random() * 10000)
        //             this.openedTabs.push(tab.name)
        //         }
        //     } else {
        //         this.$toast.error(this.$t('message.errors.grossMarginNotAvailable'),
        //             {
        //                 classes: ['icon-float-left'],
        //                 icon: 'error_outline'
        //             }
        //         )
        //     }
        // }
    },
    watch: {
        searchTerm() {
            this.computeTotals()
        }
    },
    mounted(){
        this.computeTotals()
    }
}
</script>

<style>
.filter-width-250 {
    width: 250px !important;
    max-width: 250px !important;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.text-end {
    text-align: right !important;
    padding-right: 4px !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>